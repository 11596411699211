import React, {useEffect, useRef, useState} from 'react'
import Main from "../../modules/main/main";
import Wizard from "../../modules/wizard/wizard";
import  YoutubeApi from "../../services/api/youtubeApi";
import { apiEmpower } from "../../services/api/empower";
import {states} from "../../components/wizard/options/states";
import {useNavigate, useParams} from "react-router-dom";
import {StepContext} from "../../contexts/step-context";

function EmpowerForm() {
    const isInitialMount = useRef(true);
    const navigate = useNavigate();
    const {id, step} = useParams();
    const [loading, setLoading] = useState(true);
    const [apiError, setApiError] = useState(false);
    const [createMode, setCreateMode] = useState(true);
    const [empowerId, setEmpowerId] = useState(id);
    const [activeStep, setActiveStep] = useState(0);
    const [formValues, setFormValues] = useState({
        // agree: true,
        // teacherFirstName: "Test",
        // teacherLastName: "User",
        // address: "123 Any St",
        // address2: "",
        // city: "City",
        // state: "IL",
        // zipCode: "75023",
        // phone: "6302057601",
        // email: "qazi91@gmail.com",
        // gradeYouTeach: "1st",
        // subject_you_teach: "Math",
        // primary_responsible: "yes",
        // previously_applied: "no",
        // previously_awarded: "no",
        // previously_awarded_year: "",
        // schoolName: "School Name",
        // schoolDistrict: "District Name",
        // schoolPrincipal: "Principal's name",
        // schoolWebsite: "https://www.school.com",
        // schoolAddress: "123 Any St",
        // schoolCity: "City",
        // schoolState: "IL",
        // schoolZipCode: "75023",
        // schoolPhone: "6301234567",
        // age: "30-39",
        // ethnicity: "asian",
        // gender: "male",
        // education_level: "bachelor",
        // executive_summary: "Abcd",
        // describe_the_problem: "Abcd",
        // current_classroom_objectives: "Abcd",
        // project_timeline: "Abcd",
        // requested_amount: 50,
        // detailed_project_budget: "Abcd",
        // // videoClip: "https://www.youtube.com/embed/eWOcxgPNsrI",
        // videoClip: "",
    });

    let breadcrumbs = [
        {"name": "Dashboard", "active": false},
        {"name": "EMPOWER Application", "active": false},
        // {"link": "/sfa", "name": "Create", "active": true},
    ];

    const onBack = (step) => {
        // console.log(step)
        setActiveStep(step)
    }

    const fetchData = async(id) => {
        // console.log(id)
        await apiEmpower.getSingle(id).then(async res => {
            if (res.status === 404) {
                // console.log(res);
                navigate('/')
            }
            await apiEmpower.getSettings().then(res => {
                if (!res.enable_edit_empower_application) {
                    navigate('/')
                }
            })
            setFormValues(res)
            setLoading(false)
        })
    }

    const getSettings = async() =>  {
        await apiEmpower.getSettings().then(res => {
            if (!res.enable_empower_application) {
                navigate('/')
            }
        })
    }

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            if (step !== undefined) {
                // console.log("Step exists")
                // setActiveStep(step);
            }

            if (step) {
                setActiveStep(+step - 1)
            }

            if (id) {
                breadcrumbs.push({"name": id, "active": true})
                setCreateMode(false);
                fetchData(id);

            } else {
                // fetchOpenApplications();
                getSettings()
                setLoading(false)
            }
        } else {
            // console.log("empowerId was set")
            // console.log(empowerId)
            getSettings()
            fetchData(empowerId);
        }
        // console.log("Create Mode: "+ createMode)
    }, [empowerId])

    const onSubmit = async (values, step_num) => {
        setLoading(true)
        let returned_value;
        for (let value of values.entries()) {
            if(value[0] === "video_clip" && value[1] !== ""){
                let p = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|shorts\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
                let matches = value[1].match(p);
                // console.log(matches)
                if(matches){
                    // console.log("Got a regex match")
                    // console.log(matches[1]);
                    let youtube_status = await YoutubeApi
                        .getStatus(matches[1])

                    if(youtube_status.items.length > 0){
                        // console.log(youtube_status.items[0].status)
                        // console.log("Youtube sent back a valid status")
                    }
                    else{
                        setLoading(false)
                        return {field: value[0], message: "The Youtube link status is not Public or Unlisted."};
                    }
                }
                else {
                    console.log("Going back 1")
                    setLoading(false)
                    return {field: value[0], message: "The Youtube link is incorrect."};
                }
            }
        }
        if (createMode) {
            if (step_num > 0) {
                values.append('empower_id', empowerId)
            }
            await apiEmpower.post(values).then((res) => {
                // console.log(res)
                if(res.empower_id){
                    setEmpowerId(res.empower_id);
                }
                returned_value = res;
            });
        } else {
            values.append('_method', "PUT")
            await apiEmpower.patch(id, values).then((res) => {
                returned_value = res;
            });
        }
        if(!returned_value.status){
            setActiveStep(prevState => prevState + 1)
            setApiError(false);
        } else {
          setApiError(true);
        }
        setLoading(false);


        return returned_value;
    }

    const steps = [
        {
            label: 'Agreement',
            description: `
                <h4>Available Awards</h4>
                <p>
                   The number of classroom grants awarded will depend on the total number of applicants. Grants are available for up to $2,000, pending availability and demand.  
                  <br />
                   We anticipate that we can award 50 recipients. 
                </p>
               
                <h4>Classroom Grant Funding Options</h4>
                <p>
                    Classroom grants can be used for projects and materials relating to the core subjects (Science, Literacy, Math, Art, Physical Education, Social Studies). 
                    Supplies include but are not limited to, the purchase of books, software, calculators, math manipulatives, art supplies, audio-visual equipment, and lab materials.
                </p>

                <h4>Application and Evaluation Criteria</h4>
                <h5>Each application will be evaluated by a panel of experts and given a value out of 30 points. Applications will be evaluated based on the following: </h5>
                <ul>
                    <li>Impact of meaningful instruction on students inside and outside the classroom (+5 points).</li>
                    <li>Funds will be used to develop an impactful and innovative experience for students (+5 points). </li>
                    <li>30-120 seconds video (+10 points).</li>
                    <li>Letter of recommendation from school (+10 points).</li>
                    <li>Describe the problem or community need that the educator addresses.</li>
                    <li>Describe the current classroom objectives.</li>
                    <li>Provide the project timeline.</li>
                    <li>Requested amount.</li>
                    <li>Detailed project budget including a description of how the funds will be spent and how the request amount was determined.</li>
                </ul>
                
                <h4>Eligibility</h4>
                <h5><span className="text-bold">Any elementary/middle school teacher</span> at a public or private school will be considered.</h5>
                <ul>
                    <li>Must agree to and follow ICNA Relief Terms of Use to apply and receive funds.</li>
                    <li>A copy of Social Security card.</li>
                    <li>A <span className="text-bold">personal W-9</span>.</li>
                </ul>
                
                <h4>Application Dates</h4>
                <p>Applications are due by July 20, 2022 </p>
                <h6>Within one month of receiving the award, classroom leads must submit a one-page report on the use of funds and its impact. </h6>
                <br/>
                <h4>Disclaimer/Waiver of Liability</h4>
                <p>
                    ICNA Relief does not assume any responsibility or liability for the misuse of products or services purchased with the funds from this grant. 
                    This grant aims to empower students and teachers in the classroom and at home to achieve greatness through education. Please act responsibly. 
                </p>
                
                <h4>Grant Terms & Conditions</h4>
                <ol>
                    <li>Use of Grant</li>
                    <ol type="a">
                        <li>
                            <span className="text-bold">The copy of the Grant Offer Letter must be returned to us 
                            (ICNA Relief USA) immediately, having been signed by an authorized person of your 
                            educational institution.</span> By signing it you confirm that to the best of your knowledge
                            the information given in your Grant Application is accurate and agree that your educational 
                            institution will adhere to these terms and conditions.
                        </li>
                        <li>
                            The grant may be used only for the purposes outlined in your Grant Application as approved 
                            by us. <span className="text-bold">If the grant is restricted to certain items, we will 
                            make this clear in a Grant Offer Letter.</span> If you are unable to use the grant for the 
                            specific purpose for which it was intended, you will be required to repay the grant in full. 
                        </li>
                        <li>
                            You agree and accept that you will not apply for duplicate funding in respect of any part 
                            of the project or any related administration costs that we are funding. 
                        </li>
                        <li>
                            We acknowledge that projects sometimes change as they develop. <span className="text-bold">
                            If any significant changes need to be made to the project’s scope or scale, before or 
                            during implementation, or if the project’s timetable is delayed, you must discuss this with 
                            us in advance, and obtain written approval for any variations.</span> Where changes are 
                            made without our agreement, we will reclaim any funds that have not been used for the 
                            intended purpose. 
                        </li>
                        <li>
                            <span className="text-bold">The project must start within twelve months of the date of the 
                            Grant Offer Letter</span> (unless otherwise agreed by us in writing). You must contact us 
                            immediately if the project is delayed for any reason. 
                        </li>
                        <li>
                            The grant must be spent on the agreed project within the time specified in the Grant Offer 
                            Letter (unless otherwise agreed by us in writing). On completion of a project, any unspent 
                            portion of the grant must be returned to us immediately. If, once you have received the 
                            money, you envision the timescales of your project changing, you must contact us 
                            immediately to explain the situation and request written approval for any extension of the 
                            term of your grant.
                        </li>
                        <li>
                            You may be offered a grant which will only be paid when specific conditions have been met. 
                            Such grants must be claimed in writing with evidence that you have met the conditions. We 
                            reserve the right to withhold payment of any grant if we are not satisfied that your 
                            educational institution is in a position to spend the money or if you have not met the 
                            conditions satisfactorily. 
                        </li>
                    </ol>
                    <li>Reporting</li>
                    <ol type="a">
                        <li>
                            Progress reports must be provided. Evidence of project delivery is a requirement of the 
                            grant. Progress reports are required at six-monthly intervals from the date that we 
                            inform you of your grant through a Grant Offer Letter, until completion of the project. We 
                            also require confirmation of the expenditure funded by the grant. If you do not provide 
                            progress reports as required, we reserve the right to reclaim the grant.
                        </li>
                        <li>
                            Proper accounts must be kept, both for your educational institution and for the project 
                            supported by our grant. These must be available for inspection at any reasonable time. 
                            Your educational institution audited or independently examined annual accounts for the year 
                            in which you received the grant must be sent to us by the date specified by you in the 
                            Grant Application. 
                        </li>
                        <li>
                            You must keep grant paperwork for a minimum of three years from the end of the financial year in which the grant was made. 
                        </li>
                    </ol>
                </ol>
            `,
            fields: [
                {
                    name: 'agree',
                    type: 'checkbox',
                    required: true,
                    label: '<strong>I agree to the above EMPOWER Application Requirements.</strong>',
                    error_text: 'You must agree to the terms.',
                    width: 12
                }
            ],
        },
        {
            label: 'Your Contact Info',
            fields: [
                {
                    section: 'Your Legal Name',
                    name: 'teacherNameSection'
                },
                {
                    name: 'teacherFirstName',
                    type: 'text',
                    required: true,
                    label: 'First Name (legal)',
                    error_text: "Please enter your legal first name.",
                },
                {
                    name: 'teacherLastName',
                    type: 'text',
                    required: true,
                    label: 'Last Name',
                    error_text: "Please enter the teacher's last name.",
                },
                {
                    section: 'Your Address',
                    name: 'teacherAddressSection'
                },
                {
                    name: 'address',
                    type: 'text',
                    required: true,
                    label: 'Street Address',
                    error_text: "Please enter your address.",
                },
                {
                    name: 'address2',
                    type: 'text',
                    required: false,
                    label: 'Address Line 2',
                },
                {
                    name: 'city',
                    type: 'text',
                    required: true,
                    label: 'City',
                    error_text: "Please enter your city.",
                },
                {
                    name: 'state',
                    type: 'select',
                    required: true,
                    label: 'State',
                    error_text: "Please select your state.",
                    options: states
                },
                {
                    name: 'zipCode',
                    type: 'text',
                    required: true,
                    label: 'Zip Code',
                    error_text: "Please enter your zip Code.",
                },
                {
                    section: 'Contact Information',
                    name: 'contactInformationSection'
                },
                {
                    name: 'phone',
                    type: 'text',
                    required: true,
                    label: 'Phone',
                    error_text: "Please enter your phone number.",
                    format: "tel"
                },
                {
                    name: 'email',
                    type: 'text',
                    required: true,
                    label: 'Email',
                    error_text: "Please enter your email.",
                },
                {
                    section: 'Grade You Teach',
                    name: 'gradeYouTeachSection'
                },
                {
                    name: 'gradeYouTeach',
                    type: 'text',
                    required: true,
                    label: 'Grade You Teach',
                    error_text: "Please enter the grade you teach.",
                },
                {
                    name: 'subject_you_teach',
                    type: 'text',
                    required: false,
                    label: 'Subject You Teach (Optional)',
                    error_text: "Please enter the subject you teach.",
                },
                {
                    name: 'number_of_students',
                    type: 'text',
                    required: true,
                    label: 'Number of students in your classroom?',
                    error_text: "Please enter the number of students in your classroom.",
                },
                {
                    section: 'EMPOWER Questions',
                    name: 'empowerQuestionsSection'
                },
                {
                    name: 'teaching_confirmation',
                    type: 'select',
                    required: true,
                    width: 12,
                    label: 'Do you have confirmation from your school that you will be teaching during the upcoming school year (e.g., signed contract, letter of intent, or verbal confirmation)?',
                    error_text: "Please answer this question.",
                    options: {
                        "yes": "Yes",
                        "no": "No",
                    }
                },
                {
                    name: 'primary_responsible',
                    type: 'select',
                    required: true,
                    width: 12,
                    label: 'Are you the person responsible for the execution of the grant?',
                    error_text: "Please answer this question.",
                    options: {
                        "yes": "Yes",
                        "no": "No",
                    }
                },
                {
                    name: 'previously_applied',
                    type: 'select',
                    required: true,
                    width: 12,
                    label: 'Have you previously applied for EMPOWER Grant?',
                    error_text: "Please answer this question.",
                    options: {
                        "yes": "Yes",
                        "no": "No",
                    }
                },
                {
                    name: 'previously_awarded',
                    type: 'select',
                    required: true,
                    width: 12,
                    label: 'Have you previously been awarded the EMPOWER Grant?',
                    error_text: "Please answer this question.",
                    options: {
                        "yes": "Yes",
                        "no": "No",
                    },
                    skip_field: {
                        skip_value: 'no',
                        name: "previously_awarded_year",
                        label: "If yes, which year? (It does not affect your application) ",
                        type: "text",
                        width: 12,
                        required: true,
                        error_text: "Please enter the reason for applying.",

                    },
                },
            ],
        },
        {
            label: 'School Info',
            fields: [
                {
                    section: 'School Details',
                    name: 'schoolDetailsSection'
                },
                {
                    name: 'schoolName',
                    type: 'text',
                    required: true,
                    label: 'School Name',
                    error_text: "Please enter the school's name.",
                },
                {
                    name: 'schoolDistrict',
                    type: 'text',
                    required: true,
                    label: 'School District Name',
                    error_text: "Please enter the school's district name.",
                },
                {
                    name: 'schoolPrincipal',
                    type: 'text',
                    required: true,
                    label: "Principal's Name",
                    error_text: "Please enter the principal's name.",
                },
                {
                    name: 'schoolWebsite',
                    type: 'text',
                    required: false,
                    label: "School Website (if applicable)",
                    error_text: "Please enter the schools website.",
                },
                {
                    section: 'School Address',
                    name: 'schoolAddressSection'
                },
                {
                    name: 'schoolAddress',
                    type: 'text',
                    required: true,
                    label: 'School Address',
                    error_text: "Please enter the school's address.",
                },
                {
                    name: 'schoolAddress2',
                    type: 'text',
                    required: false,
                    label: 'School Address Line 2',
                },
                {
                    name: 'schoolCity',
                    type: 'text',
                    required: true,
                    label: 'City',
                    error_text: "Please enter your school's city.",
                },
                {
                    name: 'schoolState',
                    type: 'select',
                    required: true,
                    label: 'School State',
                    options: states
                },
                {
                    name: 'schoolZipCode',
                    type: 'text',
                    required: true,
                    label: 'School Zip Code',
                    error_text: "Please enter the school's zip Code.",
                },
                {
                    section: "School's Phone Number",
                    name: 'schoolPhoneNumberSection'
                },
                {
                    name: 'schoolPhone',
                    type: 'text',
                    required: true,
                    label: 'School Phone Number',
                    error_text: "Please enter the school's phone number.",
                    format: "tel"
                },
            ]
        },
        {
            label: 'Grant Request Summary',
            fields: [
                {
                    section: "Demographic questions",
                    name: "demographicQuestionsSection"
                },
                {
                    name: 'age',
                    type: 'select',
                    required: true,
                    label: 'Choose your age group.',
                    error_text: "Please answer this question.",
                    options: {
                        "17_or_younger": "17 or younger",
                        "18-20": "18-20",
                        "21-29": "21-29",
                        "30-39": "30-39",
                        "40-49": "40-49",
                        "50-59": "50-59",
                        "60_or_older": "60 or older",
                    }
                },
                {
                    name: 'ethnicity',
                    type: 'select',
                    required: true,
                    label: 'Ethnicity/Race/Country of origin',
                    error_text: "Please answer this question.",
                    options: {
                        "afghanistan": "Afghanistan",
                        "arab": "Arab",
                        "american_indian_or_alaska_native": "American Indian or Alaska Native",
                        "asian": "Asian",
                        "black_or_african_american": "Black or African American",
                        "central_asian": "Central Asian",
                        "hispanic_or_latino": 'Hispanic or Latino',
                        "mena": "Middle Eastern North African (MENA)",
                        "multiracial_or_mixed_race": "Multiracial or Mixed Race",
                        "not_hispanic_or_latino": "Not Hispanic or Latino",
                        "native_hawaiian_or_other_pacific_islander": "Native Hawaiian or Other Pacific Islander",
                        "native_american_or_alaska_native": "Native American or Alaska Native",
                        "ukraine":"Ukraine",
                        "white":"White",
                        "other":"Other",
                        "Prefer_not_to_answer":"Prefer not to answer",
                    }
                },
                {
                    name: 'gender',
                    type: 'select',
                    required: true,
                    label: 'Gender',
                    error_text: "Please answer this question.",
                    options: {
                        "male": "Male",
                        "female": "Female",
                        "other":"Other",
                    }
                },
                {
                    name: 'education_level',
                    type: 'select',
                    required: true,
                    label: 'What is the highest level of education you have completed?',
                    error_text: "Please answer this question.",
                    options: {
                        "less_than_high_school_degree": "Less than high school degree.",
                        "high_school": "High school degree or equivalent (e.g., GED).",
                        "some_college":"Some college but no degree.",
                        "associate":"Associate degree.",
                        "bachelor":"Bachelor degree.",
                        "graduate":"Graduate degree.",
                    }
                },
                {
                    section: "Project Details",
                    name: "projectDetailsSection"
                },
                {
                    name: "executive_summary",
                    label: "Executive summary of grant application",
                    type: "text",
                    rows: 4,
                    width: 12,
                    required: true,
                },
                {
                    name: "describe_the_problem",
                    label: "Describe the problem or community need that the educator addresses",
                    type: "text",
                    rows: 4,
                    width: 12,
                    required: true,
                },
                {
                    name: "current_classroom_objectives",
                    label: "Describe the current classroom objectives",
                    type: "text",
                    rows: 4,
                    width: 12,
                    required: true,
                },
                {
                    name: "project_timeline",
                    label: "Provide the project timeline",
                    type: "text",
                    rows: 4,
                    width: 12,
                    required: true,
                },
                {
                    section: "Project Budget",
                    name: "projectBudgetSection"
                },
                {
                    name: "requested_amount",
                    label: "Requested amount",
                    type: "number",
                    width: 12,
                    required: true,
                },
                {
                    name: "detailed_project_budget",
                    label: "Detailed project budget including a description of how the funds will be spent and how the request amount was determined",
                    type: "text",
                    rows: 4,
                    width: 12,
                    required: true,
                },
            ]
        },
        {
            label: 'Youtube Video',
            fields: [
                {
                    section: "Youtube Link",
                    name: "youtubeLinkSection"
                },
                {
                    name: "video_clip",
                    label: "Video Clip (You can post an unlisted YouTube link only)",
                    type: "text",
                    width: 12,
                    required: false,
                    error_text: "Please enter a youtube video link",
                }
            ]
        },
        {
            label: 'File Upload Requests',
            fields: [
                {
                    section: "File Uploads. PLEASE ONLY UPLOAD A PDF.",
                    name: "fileUploadSection"
                },
                {
                    name: "resume",
                    label: "A brief professional résumé listing academic background, employment history, membership in professional associations, and any awards or honors received. Upload a brief professional résumé here (PDF).",
                    type: "file",
                    width: 12,
                    required: true,
                },
                {
                    name: "letterOfRecommendationFromYourSchoolPrincipal",
                    label: "Letter of Recommendation from your School Principal (PDF)",
                    type: "file",
                    width: 12,
                    required: true,

                },
                {
                    name: "socialSecurityCard",
                    label: "Social Security Card (PDF)",
                    type: "file",
                    width: 12,
                    required: true,
                    sample_url: "https://icnarelief.org/wp-content/uploads/2022/06/sample-social-security-number-card.gif",
                    sample_message: "Click here to see a sample (Opens in a tab)"
                },
                {
                    name: "personalW9",
                    label: "PersonalW9 (PDF)",
                    type: "file",
                    width: 12,
                    required: true,
                    sample_url: "https://icnarelief.org/wp-content/uploads/2022/06/W-9-EXAMPLE.pdf",
                    sample_message: "Click here to see a sample (Opens in a tab)"
                },
            ],
            onSubmit:{
                title: "Are you sure you would like to submit this application?",
                label: "THIS APPLICATION CAN NOT BE EDITED AFTER SUBMISSION.",
                options: {true: "Yes", false: "No"}
            }
        },
    ];

    return (
        <Main title="EMPOWER Application" breadcrumbs={breadcrumbs} loading={loading}>
            <div className="small-box mb-0 bg-transparent shadow-none">
                <StepContext.Provider value={{activeStep, setActiveStep}}>
                    <Wizard
                        onSubmit={onSubmit}
                        onBack={onBack}
                        steps={steps}
                        activeStep={activeStep}
                        initialValues={formValues}
                        empower_id={empowerId}
                        onCompletionMessage={"Your EMPOWER Application has been submitted successfully."}
                        apiError={apiError}
                    />
                </StepContext.Provider>
            </div>

        </Main>
    )
}

export default EmpowerForm;